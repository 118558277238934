import React, {useEffect, useState} from 'react';
import './App.scss';
import {Typography, Icon, IconButton, LinearProgress, Button} from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
    isMobile,
    isIPad13,
} from "react-device-detect";

import {ReactComponent as BowieLogo} from "./assets/bowie-bolt-spelled.svg"

import {ReactComponent as MazeLogo} from "./assets/maze.svg"

import {ReactComponent as MazeOrBowie} from "./assets/new-bowie.svg"

// import {ReactComponent as MazeOrBowie} from "./assets/maze-or-bowie.svg"

// @ts-ignore
import maze1 from "./assets/songs/1-08-16-maze.m4a"
// @ts-ignore
import bowie1 from "./assets/songs/12-31-02-bowie.m4a"
// @ts-ignore
import bowie2 from "./assets/songs/6-29-19-bowie.m4a"
// @ts-ignore
import maze2 from "./assets/songs/1-8-16-maze.m4a"
// @ts-ignore
import maze3 from "./assets/songs/12-6-97-maze.m4a"
// @ts-ignore
import bowie3 from "./assets/songs/8-16-09-bowie.m4a"
// @ts-ignore
import bowie4 from "./assets/songs/12-28-12-bowie.m4a"
// @ts-ignore
import maze4 from "./assets/songs/2-26-03-maze.m4a"
// @ts-ignore
import bowie5 from "./assets/songs/8-26-89-bowie.m4a"
// @ts-ignore
import maze5 from "./assets/songs/12-28-12-maze.m4a"
// @ts-ignore
import bowie6 from "./assets/songs/3-6-09-bowie.m4a"
// @ts-ignore
import maze6 from "./assets/songs/8-8-15-maze.m4a"
// @ts-ignore
import bowie7 from "./assets/songs/12-29-97-bowie.m4a"
// @ts-ignore
import maze7 from "./assets/songs/6-22-19-bowie.m4a"
// @ts-ignore
import neverGetOut from "./assets/youll-never-get-out.m4a"
// @ts-ignore
import everythingsRight from "./assets/6-23-19-Everythings_Right.m4a"


// add date location and venue
const songObjects = [{song: bowie1, date: "12-31-02", venue: "MSG", location: "New York, NY", value: "bowie"},
    {song: bowie2, date: "6-29-19", venue: "BB&T Pavilion", location: "Camden, NJ", value:"bowie"},
    {song: bowie3, date: "8-16-09", venue: "SPAC", location: "Saratoga Springs, NY", value:"bowie"},
    {song: bowie4, date: "12-28-12", venue: "MSG", location: "New York, NY", value:"bowie"},
    {song: bowie5, date: "8-26-89", venue: "Townshend Family Park", location: "Townshend, VT", value:"bowie"},
    {song: bowie6, date: "3-6-09", venue: "The Mothership", location: "Hampton, VA", value:"bowie"},
    {song: bowie7, date: "12-29-97", venue: "MSG", location: "New York, NY", value:"bowie"},
    {song: maze1, date: "1-8-16", venue: "Xfinity Center", location: "Mansfield, MA", value:"maze"},
    {song: maze2, date: "1-8-16", venue: "Xfinity Center", location: "Mansfield, MA", value:"maze"},
    {song: maze3, date: "12-6-97", venue: "The Palace", location: "Auburn Hills, MI", value:"maze"},
    {song: maze4, date: "2-26-03", venue: "Worcester CC", location: "Worcester, MA", value:"maze"},
    {song: maze5, date: "12-28-12", venue: "MSG", location: "New York, NY", value:"maze"},
    {song: maze6, date: "8-8-15", venue: "Alpine Valley", location: "East Troy, WI", value:"maze"},
    {song: maze7, date: "6-22-19", venue: "Merriweather Post", location: "Columbia, MD", value:"maze"}]


// add cymbal graphic high hat

function shuffle(array: Array<Object>): Array<Object> {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
}

function SongList() : Array<Object> {
    return shuffle(songObjects).slice(0, 5);
}

// @ts-ignore
function getTime(time: number): string {
    if(!isNaN(time)) {
        return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2)
    }
}

// @ts-ignore
function getPreciseTime(time: number): string {
    if(!isNaN(time)) {
        try {
            return getTime(time) + '.' + time.toString().split(".")[1].slice(0,3)

        } catch {
            return getTime(time) + '.001'

        }
    }
}

const DotRow = (props: { index: number; numItems: number; }) => {
    const {index, numItems} = props

    return (
        <div style={{display:'flex', flexDirection: 'row', width: '100vw', padding: 10, margin:10, justifyContent: 'center'}}>
            {Array.from(Array(numItems), (e, i) => {return <FiberManualRecordIcon key={i} style={i>index?{color:'gray'}: {color:'black'}}/>})}
        </div>
    )
}

function App() {
    const [started, setStarted] = useState(false);
    const [index, setIndex] = useState(0);
    const [correct, setCorrect] = useState(0);
    const [guessed, setGuessed] = useState(0);
    const [reveal, setReveal] = useState(false);
    const [end, setEnd] = useState(false);
    const [songs, setSongs] = useState(null as (null | Array<Object>));
    const [song, setSong] = useState(null as (null | HTMLAudioElement));
    const [numberOfSongs, setNumberOfSongs] = useState(0);

    const [timeTotal, setTimeTotal] = useState(0);

    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const [standardWidth, setStandardWidth] = useState("auto");
    const [standardBowieWidth, setStandardBowieWidth] = useState("auto");
    const [standardHeight, setStandardHeight] = useState("auto");
    const [standardLogoWidth, setStandardLogoWidth] = useState("auto");
    const [standardLogoHeight, setStandardLogoHeight] = useState("auto");
    const [bigWidth, setBigWidth] = useState("auto");
    const [bigHeight, setBigHeight] = useState("auto");
    const [bigLogoWidth, setBigLogoWidth] = useState("auto");
    const [bigLogoHeight, setBigLogoHeight] = useState("auto");
    const [resultTextSize, setResultTextSize] = useState("h1");
    const [resultTextSize1, setResultTextSize1] = useState("h3");
    const [resultTextSize2, setResultTextSize2] = useState("h4");



    const [distanceDown, setDistanceDown] = useState("70vh");
    const [bottomDistanceDown, setBottomDistanceDown] = useState("90vh");
    const [topDistanceDown, setTopDistanceDown] = useState("5vh");
    const [resultsDistanceDown, setResultsDistanceDown] = useState("60vh");
    const [songProgress, setSongProgress] = useState(0);


    const [logoClassname, setLogoClassname] = useState("bounce-between");


    function setSizing() {
        // const mob = CheckMobileScreen()
        // console.log(`mobile: ${mob}`)
        if (window.innerWidth >
            window.innerHeight) {
            setStandardHeight('auto');
            setBigHeight('auto');
            setStandardWidth('20vw');
            setStandardBowieWidth('30vw');
            setStandardLogoHeight('auto');
            setStandardLogoWidth('35vw');
            setBigWidth('20vw');
            setBigLogoWidth('35vw');
            setDistanceDown('50vh');
            setBottomDistanceDown('85vh');
            setTopDistanceDown("5vh");
            setResultsDistanceDown("70vh");
        } else {
            setStandardHeight('auto');
            setBigHeight('auto');
            setStandardWidth('30vw');
            setStandardBowieWidth('40vw');
            setBigWidth('80vw');
            setBigLogoWidth('90vw');
            setStandardLogoHeight('auto');
            setStandardLogoWidth('40vw');
            setTopDistanceDown('20vh');
            setDistanceDown('45vh');
            setBottomDistanceDown('75vh');
            setResultTextSize("mobile");
            setResultTextSize1("h4");
            setResultTextSize2("h5");
        }
    }
    useEffect(() => {
        // check ratios and set
        setSizing();
        const tempSongs = SongList();
        setSongs(tempSongs);
        setNumberOfSongs(tempSongs.length);

        // @ts-ignore
        const tempSong = new Audio (tempSongs[0].song)
        setSong(tempSong);

        tempSong.addEventListener("timeupdate", e => {
            // @ts-ignore
            setCurrentTime(e.target.currentTime);
            // @ts-ignore
            setDuration(e.target.duration);
        });
        window.addEventListener("resize", setSizing);
    }, [])

    useEffect(() => {

        // @ts-ignore
        setSongProgress(currentTime/duration*100);
        if (started && !end && currentTime === duration) {
        guessSong("")
    }}, [currentTime])

    useEffect(() => {
        // @ts-ignore
        if (songs !== null && !reveal && guessed === songs.length) {
            setStats();
        }},[guessed])

    function GetNewBowie() : void {
        // grade it
        // @ts-ignore
        song.pause();
        // @ts-ignore
        if (index < songs.length) {

            // @ts-ignore
            const tempSong = new Audio(songs[index+1].song);
            tempSong.addEventListener("timeupdate", e => {
                // @ts-ignore
                setCurrentTime(e.target.currentTime);
                // @ts-ignore
                setDuration(e.target.duration);
            });
            setSong(tempSong);
            setIndex(index+1);
            // @ts-ignore
            tempSong.play();
        }
    }

    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function setStats(): void {
        // @ts-ignore
        document.getElementById("date").textContent = `Total time: ${getPreciseTime(timeTotal)}`;
        // @ts-ignore
        document.getElementById("location").textContent = `Avg time: ${getPreciseTime(timeTotal/songs.length)}`;
        // @ts-ignore
        document.getElementById("venue").textContent = "";
    }
    async function guessSong(value: string): Promise<void> {
        setTimeTotal(timeTotal+currentTime);
        setReveal(true);

        // @ts-ignore
        document.getElementById("date").textContent = songs[index].date;
        // @ts-ignore
        document.getElementById("location").textContent = songs[index].location;
        // @ts-ignore
        document.getElementById("venue").textContent = songs[index].venue;
        // @ts-ignore
        song.pause();
        // @ts-ignore
        if (guessed === songs.length-1) {
            // @ts-ignore
            setLogoClassname(`${songs[index].value}-end-animation`);
        } else {
            // @ts-ignore
            setLogoClassname(`${songs[index].value}-animation`);

        }
        // wait until we hit next
        // @ts-ignore
        if (value === songs[index].value) {
            setCorrect(correct + 1);
        }


        // @ts-ignore
        if (guessed === songs.length-1) {
            setEnd(true);
            // @ts-ignore
            song.pause();




            if (correct < guessed) {
                const tempSong = new Audio(neverGetOut);
                await sleep(500); // pause here so safari 11 doesnt get mad that this si autoplaying audio
                await tempSong.play()
                setSong(tempSong);
            } else {
                const tempSong = new Audio(everythingsRight);
                await sleep(500); // pause here so safari 11 doesnt get mad that this si autoplaying audio
                await tempSong.play()
                setSong(tempSong);
            }
            await sleep(1000);
            setReveal(false);
            setStats();
        }
        setGuessed(guessed + 1);

    }

    function nextSong() : void {
        setReveal(false);
        setLogoClassname('bounce-between')
        GetNewBowie();
    }

    if (!started) {
        if (isMobile && !isIPad13) {
            return (<div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center',
                position: 'absolute', width:"100vw", height:"100vh", top:-150,
                marginLeft:'auto',
                marginRight:'auto'}}>
                <MazeLogo style={{width: '65vw',
                    height: standardHeight}} />
                <IconButton onClick={() => {setStarted(true);
                    // @ts-ignore
                    song.play();}}>
                    <Icon style={{width: '65vw',
                        height: standardLogoHeight}} >
                        <MazeOrBowie className={`play-game`} />
                    </Icon>
                </IconButton>
                <BowieLogo style={{width: '65vw',
                    height: standardHeight}} />
                <div style={{position: "absolute", top:'80vh', textAlign:"center"}}>
                    <Typography variant="h4">Rules</Typography>

                    <Typography variant="h5" style={{fontFamily: 'Open Sans Condensed'}}>Guess that high hat. Is it a Maze or a David Bowie by Phish? You have until the end of the intro to guess.</Typography>
                </div>
            </div>);
        }
        return (
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: 'center',
                    position: 'absolute', width:"100vw", height:"100vh", top:-100,
                    marginLeft:'auto',
                    marginRight:'auto'}}>
                    <MazeLogo style={{width: standardWidth,
                        height: standardHeight}} />
                        <IconButton onClick={() => {setStarted(true);
                        // @ts-ignore
                            song.play();}}>
                            <Icon style={{width: standardLogoWidth,
                                height: standardLogoHeight}} >
                                <MazeOrBowie className={`play-game`} />
                            </Icon>
                        </IconButton>
                    <BowieLogo style={{width: standardWidth,
                        height: standardHeight}} />
                    <div style={{position: "absolute", top:resultsDistanceDown, textAlign:"center"}}>
                        <Typography variant="h2">Rules</Typography>
                        <Typography variant="h4">Guess that high hat. Is it a Maze or a David Bowie by Phish? You have until the end of the intro to guess for five songs.</Typography>
                    </div>
                </div>
        );
    }
    // @ts-ignore
    else if (        // @ts-ignore
        !reveal && guessed === songs.length) {
        if (isMobile && !isIPad13) {
            return (<div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center', position: 'absolute', width:"100vw", height:"100vh"}}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center',
                    position: 'absolute', width:"100vw", height:"100vh", top:-150,
                    marginLeft:'auto',
                    marginRight:'auto'}}>
                    <MazeLogo style={{width: '65vw',
                        height: standardHeight}} />
                    <IconButton onClick={() => {
                        // @ts-ignore
                        song.pause();
                        window.location.reload(true);}}>
                        <Icon style={{width: '65vw',
                            height: standardHeight}} >
                            <MazeOrBowie className={`end-game`} />
                        </Icon>

                    </IconButton>
                    <BowieLogo style={{width: '65vw',
                        height: standardHeight}} />
                </div>
                <div style={{position: "absolute", top:'60vh', textAlign:"center"}}>
                    <Typography style={correct < guessed ? {margin:'1vh 0', color:'#d02527'}: {margin:'1vh 0', color:'#3685c6'}} variant="h4">{`${correct} of ${guessed} correct`}</Typography>
                    <Typography style={{margin:'1vh 0',fontFamily: 'Open Sans Condensed'}} variant="h4">{correct === guessed ? `Everything's right so just hold tight. Screenshot this and send it to 5 phriends.` : "You'll never get out of this maze. Please direct all frustrations to gsarkes@gmail.com"}</Typography>

                    <Typography style={{margin:'1vh 0',fontFamily: 'Open Sans Condensed'}} variant="h4">If you enjoyed this please donate to the Waterwheel foundation</Typography>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent:'center',}}>
                        <Button style={{margin:'0 1vw', color:'white', fontSize: 12, backgroundColor:'#3685c6', width: standardWidth,
                            height: '5vh',}} onClick={() => window.open("https://phish.com/waterwheel/", "_blank")}>Learn more</Button>
                        <Button style={{margin:'0 1vw', color:'white', fontSize: 12, backgroundColor:'#d02527', width: standardWidth,
                            height: '5vh',}} onClick={() => window.open("https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=TQ4UHCFWSWPSE","_blank")}>Donate</Button>
                    </div>
                    <Typography style={{margin:'1vh 0', color:'#d02527'}} variant="h4">mazeorbowie.com</Typography>
                </div>
            </div>);
        }
        return (
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center', position: 'absolute', width:"100vw", height:"100vh"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: 'center',
                    position: 'absolute', width:"100vw", height:"100vh", top:-100,
                    marginLeft:'auto',
                    marginRight:'auto'}}>
                    <MazeLogo style={{width: standardWidth,
                        height: standardHeight}} />
                    <IconButton onClick={() => {
                        // @ts-ignore
                        song.pause();
                        window.location.reload(true);}}>
                        <Icon style={{width: standardLogoWidth,
                            height: standardHeight}} >
                            <MazeOrBowie className={`end-game`} />
                        </Icon>

                    </IconButton>
                    <BowieLogo style={{width: standardWidth,
                        height: standardHeight}} />
                </div>
                <div style={{position: "absolute", top:resultsDistanceDown, textAlign:"center"}}>
                    <Typography style={{margin:'1vh 0',}} variant="h1">{`${correct} of ${guessed} correct`}</Typography>
                    <Typography style={{margin:'1vh 0',}} variant="h2">{correct === guessed ? `Everything's right so just hold tight.` : "You'll never get out of this maze."}</Typography>
                    <Typography style={{margin:'1vh 0',}} variant="h3">If you enjoyed this please donate to the Waterwheel foundation</Typography>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent:'center',}}>
                        <Button style={{margin:'0 1vw', color:'white', backgroundColor:'#3685c6'}} onClick={() => window.open("https://phish.com/waterwheel/", "_blank")}>Learn more</Button>
                        <Button style={{margin:'0 1vw', color:'white', backgroundColor:'#d02527'}} onClick={() => window.open("https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=TQ4UHCFWSWPSE","_blank")}>Donate</Button>
                    </div>
                    <Typography style={{margin:'1vh 0',}} variant="h4">mazeorbowie.com</Typography>
                </div>
            </div>
        );
    } else {
        // @ts-ignore
        // @ts-ignore
        if (isMobile && !isIPad13) {
            return (<div style={{ display: "flex", flexDirection: "column", justifyContent: "center",
                alignItems: 'center', position: 'absolute', width:"100vw", height:"100vh", top:-100,
                marginLeft:'auto',
                marginRight:'auto'}}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center',
                    position: 'absolute', top:'20vh',
                    marginLeft:'auto',
                    marginRight:'auto'}}>
                    <IconButton onClick={() => {nextSong();}} disabled={!reveal || end}>
                        <Icon style={{width: bigLogoWidth,
                            height: bigHeight}}>
                            <MazeOrBowie className={logoClassname} />
                        </Icon>
                    </IconButton>

                    <div style={{display:'flex', flexDirection: 'column', width: '100vw', justifyContent: 'center'}}>
                        <div style={{display:'flex', flexDirection: 'row', width: 'auto', justifyContent: 'center',
                            alignItems: 'center', marginTop:'3vh'}}>
                            <Typography variant="h4">{getTime(currentTime)}</Typography>
                            <LinearProgress style={{width: '60vw', backgroundColor:"lightcyan", margin:'0 1rem'}} variant="determinate" value={songProgress} />
                            <Typography variant="h4">{getTime(duration)}</Typography>
                        </div>
                    </div>
                    <div style={{position: "absolute", top:distanceDown, textAlign:"center"}}>
                        <div style={{display:'flex', flexDirection: 'row', width: '100vw', justifyContent: 'center'}}>
                            <IconButton onClick={() => guessSong("maze")} disabled={reveal}>

                                <Icon style={{width: standardWidth,
                                    height: standardHeight, margin:'0 1 rem',}}>
                                    <MazeLogo/>
                                </Icon>
                            </IconButton>
                            <IconButton onClick={() => guessSong("bowie")} disabled={reveal}>
                                <Icon style={{width: standardBowieWidth,
                                    height: standardHeight, margin:'0 1rem'}}>
                                    <BowieLogo />
                                </Icon>
                            </IconButton>
                        </div>

                    </div>
                    <div style={{position: 'absolute', top: bottomDistanceDown}}>
                        <DotRow index={index} numItems={numberOfSongs}/>
                    </div>
                </div>
            </div>);
        }
        return (    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center",
            alignItems: 'center', position: 'absolute', width:"100vw", height:"100vh", top:0,
            marginLeft:'auto',
            marginRight:'auto'}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center',
                position: 'absolute', top:topDistanceDown,
                marginLeft:'auto',
                marginRight:'auto'}}>
            <IconButton onClick={() => {nextSong();}} disabled={!reveal || end}>
                <Icon style={{width: bigLogoWidth,
                    height: bigHeight}}>
                    <MazeOrBowie className={logoClassname} />
                </Icon>
            </IconButton>

            <div style={{display:'flex', flexDirection: 'column', width: '100vw', justifyContent: 'center'}}>
            <div style={{display:'flex', flexDirection: 'row', width: '100vw', justifyContent: 'center',
                alignItems: 'center', marginTop:'3vh'}}>
                <Typography variant="h4">{getTime(currentTime)}</Typography>
                <LinearProgress style={{width: '60vw', backgroundColor:"lightcyan", marginLeft: '4vw', marginRight:'4vw'}} variant="determinate" value={songProgress} />
                <Typography variant="h4">{getTime(duration)}</Typography>
                </div>
            </div>
            <div style={{position: "absolute", top:distanceDown, textAlign:"center"}}>
                <div style={{display:'flex', flexDirection: 'row', width: '100vw', justifyContent: 'center'}}>
                    <IconButton onClick={() => guessSong("maze")} disabled={reveal}>

                        <Icon style={{width: standardWidth,
                            height: standardHeight, marginLeft:'4rem', marginRight:'4rem'}}>
                            <MazeLogo/>
                        </Icon>
                    </IconButton>
                    <IconButton onClick={() => guessSong("bowie")} disabled={reveal}>
                        <Icon style={{width: standardBowieWidth,
                            height: standardHeight, marginLeft:'4rem', marginRight:'4rem'}}>
                            <BowieLogo />
                        </Icon>
                    </IconButton>
                </div>

                </div>
                <div style={{position: 'absolute', top: bottomDistanceDown}}>
                    <DotRow index={index} numItems={numberOfSongs}/>
                </div>
            </div>
        </div>);
    }
}

export default App;
